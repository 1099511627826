'use strict';

const styles = theme => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 15,
    marginBottom: 15,
  },

  button: {
    marginLeft: 7,
    marginRight: 7
  }
});

export default styles;