'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

// const MASKS = {
//   us_phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
// };

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      style={{ paddingRight: 0, paddingLeft: 10 }}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
      mask="_"
      isNumericString
      isAllowed={(values) => {
        const {value, floatValue} = values;
        if (typeof floatValue==='undefined' || typeof value==='undefined') return true;

        return value.charAt(0) !== '0';
      }}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextMaskCustom;
