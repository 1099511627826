import JobModel from 'matchboards_react_core/models/Job';

export const occupations = [
  'md',
  'pa',
  'np',
  'crna',
  'caa',
  'nurse_midwife',
  'nurse_specialist',
  'rn',
  'lvn_lpn',
  'certified_na',
  'pct',
  'therapist',
  'technologist',
  'technician',
  'medical_assistant',
  'dentist',
  'behavioral_health',
  'dietitian',
  'pharmacist',
  'administration',
];

export const occupationEntries = occupations.map((o) => (
  { id: o, label: I18n.t(`direct_shifts.professionals.occupations.${o}`) }
));

const JobConfigurations = {
  job_categories: [
    {
      value: JobModel.CATEGORY_LOCUM,
      label: I18n.t(`direct_shifts.jobs.categories.${JobModel.CATEGORY_LOCUM}`),
    },
    {
      value: JobModel.CATEGORY_PER_DIEM,
      label: I18n.t(`direct_shifts.jobs.categories.${JobModel.CATEGORY_PER_DIEM}`),
    },
    {
      value: JobModel.CATEGORY_PERMANENT,
      label: I18n.t(`direct_shifts.jobs.categories.${JobModel.CATEGORY_PERMANENT}`),
    },
  ],
  categories_short_term: [JobModel.CATEGORY_LOCUM, JobModel.CATEGORY_PER_DIEM],
  occupations: occupationEntries,
};

export default JobConfigurations;
