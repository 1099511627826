import { alpha } from '@mui/material';
import grey from '@mui/material/colors/grey';
import lightBlue from '@mui/material/colors/lightBlue';
import lightGreen from '@mui/material/colors/lightGreen';
import red from '@mui/material/colors/red';
import createTheme from '@mui/material/styles/createTheme';

const defaultTheme = createTheme();

const blueReferral = {
  light: '#6BBAFF',
  main: '#2C8ACD',
  dark: '#005D9C',
};

const themeOverrides = (secondaryColor) => ({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        containedSecondary: {
          color: 'white',
        },
        containedSizeSmall: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: grey[300],
            '&:hover': {
              borderColor: defaultTheme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(grey[500], 0.65),
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          color: defaultTheme.palette.getContrastText(
            secondaryColor ? secondaryColor.main : grey[100],
          ),
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
          alignItems: 'center',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 48,
          '@media (min-width: 0px)': {
            minHeight: 48,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '12.5px 18px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          backgroundColor: 'white',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: secondaryColor ? secondaryColor.main : lightBlue['400'],
            color: defaultTheme.palette.getContrastText(
              secondaryColor ? secondaryColor.main : grey[100],
            ),
          },
          '&.Mui-disabled': {
            color: lightBlue['100'],
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryColor ? secondaryColor.main : lightBlue.A200,
          color: 'white',
        },
        penIconButton: {
          visibility: 'hidden',
        },
      },
    },
    PrivatePickersToolbarText: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        selected: {
          color: 'white',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: secondaryColor ? secondaryColor.main : lightGreen[500],
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 160,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },
});

const appThemeOptions = {
  palette: {
    contrastThreshold: 3.1,
    tonalOffset: 0.07,
    primary: {
      light: '#D6D6D6',
      main: '#999999',
      dark: '#5C5C5C',
      contrastText: defaultTheme.palette.getContrastText('#999999'),
    },
    secondary: {
      ...blueReferral,
      contrastText: defaultTheme.palette.getContrastText(lightBlue.A400),
    },
    success: {
      light: '#84EBB4',
      main: '#50E293',
      dark: '#22D375',
    },
    error: {
      light: '#EA8585',
      main: '#E04B4B',
      dark: '#D12323',
    },
  },
  // Arranged from light to dark for each color
  appColor: {
    // red
    cosmos: '#FFD8D8',
    bitterSweet: '#FF5B5B',
    red: red[500],
    darkRed: '#D2042D',

    // orange
    squash: '#EA9114',
    orange: '#EF6707',
    fire: '#FF6723',

    // yellow
    papayaWhip: '#FDEDD4',
    brightSun: '#F9D33A',

    // green
    aquaSpring: '#E5FBE5',
    algaeGreen: '#1BBB4E',
    swampGreen: '#668A00',

    // blue
    darkGrayishBlue: '#86939E',
    satinSoftBlue: '#99ABC6',
    secondaryTextColor: '#029ECD',
    onahau: '#CCECF5',
    pacificBlue: '#00A0D0',
    waterBlue: '#1C8EE1',
    cornflowerBlue: '#4285D0',

    // violet
    lightLavender: '#EDEDFD',
    qingDynastyDragon: '#4343E5',
    dolphin: '#655E84',
    auroMetalSaurus: '#667085',

    // grey & black
    lightGray: '#DDDDDD',
    platinum: '#E5E5E5',
    altoGray: '#D6D6D6',
    philippineSilver: '#B8B8B8',
    coolGray: '#A2B2B9',
    sonicSilver: '#7A7A7A',
    steelGray: '#777D85',
    doveGray: '#6D6D6D',
    subTitleGrey: '#5D5D5D',
    zambezi: '#5C5C5C',
    charcoalGray: '#313842',
    mineShaftGray: '#3D3D3D',
    veryDarkGray: '#3E3E3E',
    darkGunMetal: '#20202D',
    sharkBlack: '#202024',
    eerieBlack: '#1F1F1F',

    // white
    titaniumWhite: '#E5E5E5',
    powderWhite: '#EBEFF5',
    sand: '#F5F5F5',

    // Others
    lightCerulean: '#00A0D01A',
  },
  appDimensions: {
    maxWidth: 2048,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  ...themeOverrides(),
};

const appTheme = createTheme(appThemeOptions);

// Use https://material.io/resources/color/ for tweaking
const redd = {
  clinician: {
    light: '#8181EE',
    main: '#4343E5', // qingDynastyDragon
    dark: '#1B1BC5',
  },
  employer: {
    light: '#62EF7D',
    main: '#1BBB4E', // algaeGreen
    dark: '#00891F',
  },
  system: {
    light: '#68BEFF',
    main: '#1C8EE1', // waterBlue
    dark: '#0061AF',
  },
};

export const reddTheme = (userType) => createTheme({
  ...appThemeOptions,
  palette: {
    ...appThemeOptions.palette,
    secondary: {
      ...appThemeOptions.palette.secondary,
      ...redd[userType],
    },
  },
  ...themeOverrides(redd[userType]),
});

export default appTheme;
