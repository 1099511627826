import _entries from 'lodash/entries';

/**
 * Returns states for usage in dropdowns from en.yml
 *
 * @param excludeAnyState {boolean} suggests if it should include "Any" state
 * @returns {object}
 */
export const States = (excludeAnyState = true) => {
  let states = Object.assign({}, I18n.t('direct_shifts.states'));
  delete states['any'];
  excludeAnyState && (delete states['61']);
  return states;
};

/**
 * Returns states array to be used for dropdown/autosuggest
 *
 * @param addEmptyValue {boolean} suggests if it should add an empty value on top
 * @param excludeAnyState {boolean} suggests if it should include "Any" state
 * @param useId {boolean} [{id: xx, label: xx}] vs [{value: xx, label: xx}]
 * @returns {*}
 */
export const StatesArray = (addEmptyValue = true, excludeAnyState = true, useId = false) => {
  let idKey = useId ? 'id' : 'value';
  let states = _entries(States(excludeAnyState)).map(e => ({ [idKey]: e[0], label: e[1] }));
  addEmptyValue && states.unshift({ label: '', [idKey]: '' });
  return states;
}

export const StateCodesArray = (
  addEmptyValue = true,
  addOperator = true,
  excludeAnyState = true,
) => {
  let states = Object.values(States(excludeAnyState)).map((state) => ({
    label: state,
    value: state.split(' - ')[0],
  }));

  addOperator && states.unshift({ label: '&&', value: '&' });
  addEmptyValue && states.unshift({ label: '', value: '' });

  return states;
};

export const getState = (id, options = { includeName: false }) => {
  const state = I18n.t(`direct_shifts.states.${id}`);
  if (options['includeName']) {
    return state;
  }
  return state.split(' - ')[0];
}
