/* eslint no-console:0 */
// NOTE: To be included only once in a flow at entry
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-polyfill';

import React from 'react';
import { render } from 'react-dom';
import I18n from 'i18n-js';
import moment from 'moment';

import _entries from 'lodash/entries';
import _isEmpty from 'lodash/isEmpty';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';

import appTheme from '../components/WebApp/theme';
import ShareButtons from '../components/ShareButtons';
import TextMaskCustom from '../components/TextMaskCustom';

import JobConfigurations from "../components/Jobs/config";
import { StatesArray } from "../utils/States";

const { defaultLocale, locale, translations } = window.I18n;
I18n.defaultLocale = defaultLocale;
I18n.locale = locale;
I18n.translations = translations;
window.I18n = I18n;

function renderWithAppTheme(c, containerId) {
  const appComponent = (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        {c}
      </ThemeProvider>
    </StyledEngineProvider>
  );
  // Get warning on unsafe lifecycle and legacy component/api usages in console while developing
  // https://reactjs.org/docs/strict-mode.html
  const rootComponent = process.env.NODE_ENV === 'development' ? (<React.StrictMode>{appComponent}</React.StrictMode>) : appComponent;
  render(rootComponent, document.getElementById(containerId));
}

function renderEmailField() {
  renderWithAppTheme(
    <TextField
      autoFocus={true}
      id="user_email"
      label="Email"
      margin="normal"
      name="user[email]"
      required
      style={{width: '100%'}}
      type="email"
    />,
    'user_email_container'
  );
}

function renderPhoneField(required) {
  renderWithAppTheme(
    <TextField
      autoFocus={false}
      id="user_phone"
      InputProps={{ inputComponent: TextMaskCustom }}
      label="Phone"
      margin="normal"
      name="user[phone]"
      required={required}
      style={{width: '100%'}}
      type="text"
    />,
    'user_phone_container'
  );
}

function renderPasswordField(name = 'password', label = 'Password', autoFocus = false, required = true) {
  renderWithAppTheme(
    <TextField
      autoFocus={autoFocus}
      autoComplete="off"
      id={`user_${name}`}
      label={label}
      margin="normal"
      name={`user[${name}]`}
      required={required}
      style={{width: '100%'}}
      type="password"
    />,
    `user_${name}_container`
  );
}

function renderPlainTextField(name, label, required = true, defaultValue = undefined, helperText = undefined) {
  renderWithAppTheme(
    <TextField
      autoFocus={false}
      autoComplete="off"
      id={`user_${name}`}
      label={label}
      margin="normal"
      name={`user[${name}]`}
      helperText={helperText}
      required={required}
      style={{width: '100%'}}
      type="text"
      defaultValue={defaultValue}
    />,
    `user_${name}_container`
  );
}

function renderDateTimePicker(name, label, required = true, defaultValue = undefined, helperText = undefined) {
  renderWithAppTheme(
    <div>
      <input type="hidden" name={`user[${name}]`} id={`user_${name}`} />
      <TextField
        autoFocus={false}
        autoComplete="off"
        id={`user_${name}_input`}
        readonly
        margin="normal"
        name={`user[${name}_input]`}
        helperText={helperText}
        required={required}
        style={{width: '100%'}}
        type="datetime-local"
        defaultValue={defaultValue}
        onChange={(event) => document.getElementById(`user_${name}`).value = new Date(event.target.value).toISOString()}
      />
    </div>,
    `user_${name}_container`
  );
}

function renderButton(containerId, name, color = 'secondary', variant = 'contained') {
  renderWithAppTheme(
    <Button color={color} variant={variant} style={{marginRight: 10}}>
      {name}
    </Button>, containerId
  )
}

function renderAutoComplete(containerId, name, options, defaultValue, element = null,
                            reset_elements = []) {
  renderWithAppTheme(
    <Autocomplete
      id={containerId}
      getOptionLabel={option => option.label || option}
      options={options}
      defaultValue={defaultValue}
      renderInput={(params) => <TextField {...params} label={name}/>}
      onChange={(event, selectedValue) => {
        if (element) {
          element.value = _isEmpty(selectedValue) ? '' : selectedValue.value || selectedValue;
        }

        if (reset_elements.length > 0) {
          reset_elements.forEach(e => {
            element = document.querySelectorAll(`input[id=${e}]`);
            element[0].value = '';
          });
        }
      }}
    />, containerId
  );
}

function renderFileUploadField(containerId, name, label,
  fileType = '.csv', maxFileSize = 500, color = 'secondary', variant = 'contained') {
  const fileNameField = document.getElementById(`${containerId}_name`);
  renderWithAppTheme(
    <React.Fragment>
      <input
        accept={fileType}
        style={{ display: 'none' }}
        id="uploaded_file"
        name={name}
        onChange={({ target }) => {
          fileNameField.style.color = 'inherit';
          if (target && target.files) {
            const file = target.files[0];
            if (file.size / 1024 / 1024 > maxFileSize) {
              target.value = fileNameField.innerHTML = '';
              fileNameField.style.color = 'red';
              fileNameField.innerHTML = `Document size exceeds ${maxFileSize}MB.`;
              return;
            }

            // will check whether the file can be uploaded or not
            // If you select a file from googledrive, dropbox , onedrive, it will throw error because file can't be accessed
            file.slice(0, 1).arrayBuffer().then(() => {
              fileNameField.innerHTML = file.name;
            }).catch(() => {
              target.value = fileNameField.innerHTML = '';
              fileNameField.style.color = 'red';
              fileNameField.innerHTML = 'Unable to upload the selected file. Access Denied';
            });
          }
        }}
        type="file"
      />
      <label htmlFor="uploaded_file">
        <Button color={color} variant={variant} component="span" style={{textTransform: 'capitalize', width: 'max-content'}}>
          {label}
        </Button>
      </label>
    </React.Fragment>, containerId
  )
}

function renderTextQuestionField(field) {
  renderWithAppTheme(
    <div>
      <InputLabel
        required={field.required}
        style={{
          color: '#4D4E4C',
          fontWeight: '500',
          fontSize: '20px',
          fontFamily: 'Nunito Sans, Helvetica, Arial, sans-serif',
          lineHeight: '25px',
          whiteSpace: 'pre-wrap'
        }}
      >
        {field.label}
      </InputLabel>
      <Input
        id={`form_data_${field.name}_input`}
        margin="dense"
        name={`form_data[${field.name}]`}
        required={field.required}
        style={{ width: '100%', marginTop: '25px', marginBottom: '25px' }}
        type='text'
      />
    </div>,
    `user_form_${field.name}_container`
  );
}

function renderRadioQuestionField(field) {
  renderWithAppTheme(
    <div>
      <FormLabel
        required={field.required}
        style={{
          color: '#4D4E4C',
          fontWeight: '500',
          fontSize: '20px',
          fontFamily: 'Nunito Sans, Helvetica, Arial, sans-serif',
          lineHeight: '25px',
          whiteSpace: 'pre-wrap'
        }}
      >
        {field.label}
      </FormLabel>
      <RadioGroup aria-label='options' name={`form_data[${field.name}]`} style={{ marginTop: '25px' }}>
        {
          field.options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio size='small' required={field.required} />}
              label={option.label}
            />
          ))
        }
      </RadioGroup>
    </div>,
    `user_form_${field.name}_container`
  );
}

function renderRatingQuestionField(field) {
  renderWithAppTheme(
    <div>
      <FormLabel
        required={field.required}
        style={{
          color: '#4D4E4C',
          fontWeight: '500',
          fontSize: '20px',
          fontFamily: 'Nunito Sans, Helvetica, Arial, sans-serif',
          lineHeight: '25px',
          whiteSpace: 'pre-wrap'
        }}
      >
        {field.label}
      </FormLabel>
      <Rating
        id={`form_data_${field.name}_rating`}
        name={`form_data[${field.name}]`}
        style={{ marginLeft: '25px', verticalAlign: 'middle' }}
      />
    </div>,
    `user_form_${field.name}_container`
  );
}

function renderShareButtons(url, text, subject, socialIconSize) {
  renderWithAppTheme(
    <div>
      <div class='apply-title'>Share this Job</div>
      <ShareButtons
        url={url}
        text={text}
        subject={subject}
        socialIconSize={socialIconSize}
        copyButton={true}
      >
      </ShareButtons>
      <div class='apply-title'>Apply to Job</div>
    </div>
    , 'share_buttons_container'
  );
}

function setUrlParams(url, params) {
  url = new URL(url);
  for(let [key, value] of _entries(params)) {
    url.searchParams.set(key, value)
  }
  return url.href;
}

function httpGet(url) {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", url, false);
  xmlHttp.send(null);
  return JSON.parse(xmlHttp.responseText);
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('web_app_user');
  const props = JSON.parse(node.getAttribute('data'));
  switch (props.mode) {
    case 'sign_in':
      renderEmailField();
      renderPasswordField();
      renderWithAppTheme(
        <FormControlLabel
          control={<Checkbox value="1" name="user[remember_me]" />}
          label="Remember Me" />,
        'user_remember_me_container'
      );
      break;

    case 'sign_up':
      renderEmailField();
      renderPasswordField();
      renderPasswordField('password_confirmation', 'Password Confirmation');
      renderPlainTextField('first_name', 'First Name');
      renderPlainTextField('last_name', 'Last Name');
      break;

    case 'confirm_email':
    case 'forgot_password':
      renderEmailField();
      break;

    case 'accept_invitation':
    case 'change_password':
      renderPasswordField('password', 'New password', true);
      renderPasswordField('password_confirmation', 'Confirm new password');
      break;

    case 'send_invitation':
      renderEmailField();
      renderPlainTextField('first_name', 'First Name');
      renderPlainTextField('last_name', 'Last Name');
      renderPhoneField(false);
      break;

    case 'quick_application':
      const spinner = document.getElementById('quick_spinner_container');
      let utm_params = {
        'utm_source': 'pjobsshare',
        'utm_campaign': moment(new Date()).format('YYYYMMDD')
      }
      const allowed_uploads = '.pdf, .doc, .docx';
      const occupations = JobConfigurations.occupations.map(e => ({
        value: e.id,
        label: e.label
      }));
      const specialties = httpGet(`${window.location.origin}/api/v1/specialties/active_specialties`);
      const states = StatesArray(false);

      const specialtyElement = document.getElementById('job_specialty');
      const stateElement = document.getElementById('job_state');

      const userOccupationElement = document.getElementById('user_occupation');
      const userSpecialtyElement = document.getElementById('user_specialty');
      const userStateElement = document.getElementById('user_state');

      const selectedOccupation = occupations.find(x => x.value === userOccupationElement.value);
      const selectedSpecialty = specialties.find(x => x.label === specialtyElement.value);
      const selectedState = stateElement.value === 'ANY' ? null : states.find(x => x.label.includes(`${stateElement.value} -`));

      userSpecialtyElement.value = selectedSpecialty.value;
      if(selectedState != null) {
        userStateElement.value = selectedState.value;
      }

      renderEmailField();
      renderPlainTextField('first_name', 'First Name');
      renderPlainTextField('last_name', 'Last Name');
      renderPhoneField(true);
      renderShareButtons(setUrlParams(location.href, utm_params),
        I18n.t('direct_shifts.share_job.text'),
        I18n.t('direct_shifts.share_job.subject'), 40);
      renderFileUploadField('user_cv_container', 'cv', 'Upload', allowed_uploads, 50);
      renderAutoComplete('user_occupations_container', 'Occupation', occupations, selectedOccupation, userOccupationElement, ['user_specialties_container', 'user_states_container', 'user_specialty', 'user_state']);
      renderAutoComplete('user_specialties_container', 'Specialty', specialties, selectedSpecialty, userSpecialtyElement, ['user_states_container', 'user_state']);
      renderAutoComplete('user_states_container', 'State', states, selectedState, userStateElement);
      renderPlainTextField('zipcode', 'Zipcode');

      spinner.remove();
      break;

    case 'forms':
      props.fields.map((f) => {
        switch (f.type) {
          case 'text':
            renderTextQuestionField(f);
            break;

          case 'radio_button':
            renderRadioQuestionField(f);
            break;

          case 'rating':
            renderRatingQuestionField(f);
            break;
        }
      });
      break;

    case 'others':
      props.fields.map((f) => {
        switch (f.type) {
          case 'text':
            renderPlainTextField(f.name, f.label, f.required, f.defaultValue, f.helperText);
            break;
          case 'file':
            renderFileUploadField(f.container, "uploaded_file", f.text, f.fileType);
            break;
          case 'password':
            renderPasswordField(f.name, f.label, f.autoFocus);
            break;
          case 'datetime':
            renderDateTimePicker(f.name, f.label, f.required, f.defaultValue, f.helperText);
            break;
          case 'select':
            const selectElement = document.getElementById(f.selectElementId);
            renderAutoComplete(f.container, f.label, f.options, f.defaultValue, selectElement);
        }
      });
      break;
  }

  const formSubmit = document.getElementById('user_form_submit');
  renderWithAppTheme(
    <label htmlFor="user_form_submit">
      <Button color="secondary" component="span" variant="contained" style={{width: '100%', padding: '11px'}}>
        {formSubmit.value}
      </Button>
    </label>,
    'user_form_submit_container'
  );
});
