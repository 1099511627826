import React from 'react';
import propTypes from 'prop-types';

import _get from 'lodash/get';

import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link'

import styles from './styles';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';

class ShareButtons extends React.Component{
  static propTypes = {
    copyButton: propTypes.bool,
    socialIconSize: propTypes.number,
    subject: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    url: propTypes.string.isRequired
  };

  static defaultProps = {
    copyButton: false,
    socialIconSize: 45
  };

  constructor(props) {
    super(props);
    this.state = {copied: false};
  }

  getUrl = (medium) => {
    let url = new URL(_get(this.props, 'url'));
    url.searchParams.set('utm_medium', medium);
    url.rel = "noopener nofollow";
    return url
  };

  handleCopy = () => {
    navigator.clipboard.writeText(_get(this.props, 'url'))
    this.setState({copied: true});
  };

  handleCopied = () => {
    this.setState({copied: false})
  };

  render() {
    const { classes, copyButton, socialIconSize, subject, text } = this.props;

    return (
      <div className={classes.container}>
        <EmailShareButton
          url={this.getUrl('email')}
          subject={subject}
          body={text}
          className={classes.button}
        >
          <EmailIcon size={socialIconSize} round={true} />
        </EmailShareButton>
        <FacebookShareButton
          url={this.getUrl('facebook')}
          quote={text}
          className={classes.button}
        >
          <FacebookIcon size={socialIconSize} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={this.getUrl('linkedin')}
          title={text}
          className={classes.button}
        >
          <LinkedinIcon size={socialIconSize} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={this.getUrl('twitter')}
          title={text}
          className={classes.button}
        >
          <TwitterIcon size={socialIconSize} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={this.getUrl('whatsapp')}
          title={text}
          className={classes.button}
        >
          <WhatsappIcon size={socialIconSize} round={true} />
        </WhatsappShareButton>

        { copyButton &&
          <React.Fragment>
            <Tooltip title='Copy Link' arrow>
              <IconButton
                onClick={this.handleCopy}
                className={classes.button}
                style={{ background: "#e01d1d", color: "white", width: socialIconSize, height: socialIconSize }}>
                  <LinkIcon />
              </IconButton>
            </Tooltip>
            <Snackbar
              open={this.state.copied}
              onClose={this.handleCopied}
              autoHideDuration={4000}
              message="Link Copied to Clipboard!"
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ShareButtons);